import React from 'react';

const Home = () => {
  return (
    <section id="home" className="flex items-center justify-center h-screen text-white relative z-10">
      <div className="text-center p-6">
      </div>
    </section>
  );
}

export default Home;