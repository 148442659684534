import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import './App.css';

const App = () => {
  return (
    <div>
      <Helmet>
        <title>Jonas Dormaels</title>
      </Helmet>
      <Header />
      <main className="main-content">
        <Home />
      </main>
      <Footer />
    </div>
  );
}

export default App;