import React from 'react';
import { FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa';

const Header = () => {
  return (
    <header className="absolute top-0 left-0 w-full z-10">
      <nav className="container mx-auto p-4 flex justify-between items-center">
        <div className="text-lg font-bold text-white">Jonas Dormaels</div>
        <ul className="flex space-x-6">
          <li><a href="https://www.linkedin.com/in/jonas-dormaels-650427185/" target="_blank" rel="noopener noreferrer" className="text-xl text-white hover:text-gray-400"><FaLinkedin /></a></li>
          <li><a href="https://github.com/JonasDormaels" target="_blank" rel="noopener noreferrer" className="text-xl text-white hover:text-gray-400"><FaGithub /></a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;